import { createSlice } from '@reduxjs/toolkit';
import { accessAccountUser, loginUser } from './authActions';

const sessionId = localStorage.getItem('sessionId');
const storedSelectedRegion = localStorage.getItem('selectedRegion');
if (!storedSelectedRegion) {
    localStorage.setItem('selectedRegion', 'es');
}
let storedSelectedWebsites: any = null;
try {
    storedSelectedWebsites = localStorage.getItem('selectedWebsites') ? JSON.parse(localStorage.getItem('selectedWebsites') || '') : null;
} catch (err) {
    console.log('cannot parse the selected websites ...', err);
}

const user = {
    id: '65e6e81286fc99b950b0f124',
    username: 'defaultuser@crawlo.com',
    email: 'defaultuser@crawlo.com',
    resetToken: '',
    acceptPrivacy: true
};
const storedUserInfo = localStorage.getItem('userInfo')
    ? localStorage.getItem('userInfo')
    : localStorage.setItem('userInfo', JSON.stringify(user));
const userRegions = localStorage.getItem('userRegions');
const userWebsites = localStorage.getItem('userWebsites');
const isDarkTheme = localStorage.getItem('theme');

const selectedRegion = localStorage.getItem('selectedRegion') || 'es';

const websitesRegions = userWebsites ? JSON.parse(userWebsites).filter((el: any) => el.region == selectedRegion)[0].websites : [];

const storedDefaultWebsite = websitesRegions.filter((elm: any) => elm.default);
const storedIsMMS = localStorage.getItem('isMMS');

const initialState = {
    isLoggedIn: sessionId ? true : false,
    isDark: isDarkTheme == 'dark' ? true : false,
    selectedRegion: storedSelectedRegion ? storedSelectedRegion : '',
    selectedWebsites: storedSelectedWebsites ? storedSelectedWebsites : websitesRegions,
    userRegions: userRegions ? userRegions.split(',') : [],
    userWebsites: userWebsites ? JSON.parse(userWebsites) : [],
    userInfo: storedUserInfo ? JSON.parse(storedUserInfo) : {},
    isMMS: storedIsMMS ? storedIsMMS : false,
    isPrivacy: false,
    defaultWebsite: storedDefaultWebsite && storedDefaultWebsite.length ? storedDefaultWebsite[0] : {},
    accessAccountUser: [],
    menuItem: [],
    loadingMenuItems: true,
    loading: false,
    error: false,
    success: false,
    loadingAccess: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        userLogging: (state) => {
            state.isLoggedIn = true;
        },
        userLoggingOut: () => {
            localStorage.clear();
            return {
                isLoggedIn: false,
                isDark: false,
                selectedRegion: '',
                selectedWebsites: storedSelectedWebsites ? storedSelectedWebsites : websitesRegions,
                userRegions: [],
                userWebsites: [],
                userInfo: {},
                isMMS: false,
                isPrivacy: false,
                defaultWebsite: {},
                accessAccountUser: [],
                menuItem: [],
                loadingMenuItems: false,
                loading: false,
                error: false,
                success: false,
                loadingAccess: false
            };
        },
        selectRegion: (state, { payload }) => {
            localStorage.setItem('selectedRegion', payload);
            state.selectedRegion = payload;
            state.isLoggedIn = true;
        },
        updateRegion: (state, { payload }) => {
            localStorage.setItem('selectedRegion', payload);
            state.selectedRegion = payload;
        },
        addUserConfig: (state, { payload }) => {
            state.accessAccountUser = payload;
        },
        addMenuItem: (state, { payload }) => {
            state.menuItem = payload;
        },
        loadMenuItem: (state, { payload }) => {
            state.loadingMenuItems = payload;
        },
        updateIsMMS: (state, { payload }) => {
            localStorage.setItem('isMMS', payload);
            state.isMMS = payload;
        },
        updatedefaultWebsite: (state, { payload }) => {
            localStorage.setItem('defaultWebsite', JSON.stringify(payload));
            state.defaultWebsite = payload;
        },
        updateUserRegions: (state, { payload }) => {
            localStorage.setItem('userRegions', payload.join(','));
            state.userRegions = payload;
        },
        updateUserWebsites: (state, { payload }) => {
            localStorage.setItem('userWebsites', JSON.stringify(payload));
            localStorage.setItem('selectedWebsites', JSON.stringify(payload));
            state.userWebsites = payload;
            state.selectedWebsites = payload;
        },
        updateUserInfo: (state, { payload }) => {
            localStorage.setItem('userInfo', JSON.stringify(payload));
            state.userInfo = payload;
            state.isPrivacy = payload.acceptPrivacy;
        },
        updatePrivacy: (state, { payload }) => {
            state.isPrivacy = payload;
        },
        updateSelectedWebsites: (state, { payload }) => {
            localStorage.setItem('selectedWebsites', JSON.stringify(payload));
            state.selectedWebsites = payload;
        },
        changeTheme: (state) => {
            state.isDark = !state.isDark;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state: any) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(loginUser.fulfilled, (state: any, { payload }: any) => {
                state.error = false;

                const userInfo = payload?.login?.user;

                if (userInfo) {
                    const userSessionId = userInfo.id;

                    localStorage.setItem('sessionId', userSessionId);
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    state.userInfo = userInfo;
                    state.isLoggedIn = true;
                    state.isPrivacy = userInfo.acceptPrivacy;
                } else {
                    state.error = payload?.login?.message;
                    state.loading = false;
                }
            })
            .addCase(loginUser.rejected, (state: any) => {
                state.loading = false;
                state.error = false;
            })
            .addCase(accessAccountUser.fulfilled, (state: any, { payload }: any) => {
                state.loading = false;
                state.error = false;

                state.accessAccountUser = payload.accesses.list;
                const regions: string[] = payload.accesses.list.map((item: any) => item.account.config.region);
                const websites: any[] = payload.accesses.list.map((item: any) => {
                    const defaultWebsite = item.account.website;
                    return {
                        region: item.account.config.region,
                        websites: item.account.websites.map((website: any) => ({
                            alias: website.alias,
                            name: website.name,
                            id: website.id,
                            data: website.data,
                            default: website.id == defaultWebsite.id,
                            url: website.url
                        }))
                    };
                });

                if (regions.length) {
                    state.userRegions = regions;
                    localStorage.setItem('userRegions', regions.join(','));
                }
                if (websites.length) {
                    state.userWebsites = websites;
                    localStorage.setItem('userWebsites', JSON.stringify(websites));
                    localStorage.setItem('selectedWebsites', JSON.stringify(websites));
                }
            });
    }
});

export const {
    userLoggingOut,
    userLogging,
    selectRegion,
    addUserConfig,
    addMenuItem,
    loadMenuItem,
    updateRegion,
    updatedefaultWebsite,
    updateUserRegions,
    updateUserWebsites,
    updateUserInfo,
    updateIsMMS,
    updatePrivacy,
    changeTheme,
    updateSelectedWebsites
} = authSlice.actions;

export default authSlice.reducer;
