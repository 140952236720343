import { Dialog, DialogTitle, DialogContent, Typography, IconButton, Link, Stack, Grid } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import CountryCard from 'components/cards/CountryCard';

interface CountryModalProps {
    open: boolean;
    items: [];
    handleClose: () => void;
    handleClick: (region: string) => void;
}

function CountryModal({ open, items, handleClose, handleClick }: CountryModalProps) {
    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ sx: { borderRadius: '20px' } }}>
            <DialogTitle>
                <Typography variant="h5" component="p">
                    Select your country
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>
                <Grid container spacing={0}>
                    {items.map((item: any) => (
                        <Grid xs={3} item key={item}>
                            <CountryCard countryCode={`${item}`.toUpperCase()} handleClick={() => handleClick(item)} />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>

            <Stack direction="column" alignItems={'center'} justifyContent={'center'} sx={{ py: 1 }}>
                <Typography variant="body2" gutterBottom color={'text.secondary'}>
                    Your account is available for those countries, please request a new access if needed to
                </Typography>

                <Link href="mailto: hello@crawlo.com" underline="none" variant="body2">
                    hello@crawlo.com
                </Link>
            </Stack>
        </Dialog>
    );
}

export default CountryModal;
