import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const userWebsites = localStorage.getItem('userWebsites');
const selectedRegion = localStorage.getItem('selectedRegion') || 'es';

const websitesRegions = userWebsites ? JSON.parse(userWebsites).filter((el: any) => el.region == selectedRegion)[0].websites : [];
const storedDefaultWebsite = websitesRegions.filter((elm: any) => elm.default)[0];

const months = ['es', 'pt'].includes(selectedRegion.toLowerCase()) ? { count: 1, type: 'months' } : { count: 8, type: 'weeks' };

const initialState: any = {
    inputList: [{ id: '1', fieldSearch: 'Keywords', checkOption: 'Containing', includeExclude: 'Include', searchkey: [] }],
    checkFilter: {
        bundles: true,
        refurbished: true,
        instock: true,
        hideMarketplace: true
    },
    datePicker: storedDefaultWebsite
        ? moment(new Date(storedDefaultWebsite.data.lastDataDate), 'MM-DD-YYYY')
        : moment(new Date(), 'MM-DD-YYYY'),
    dateRangePicker: {
        startDate: moment(moment().subtract(months.count as any, months.type as any), 'MM-DD-YYYY'),
        endDate: moment(moment(), 'MM-DD-YYYY')
    },
    amazonFilterField: 'amazon-category',
    amazonBestSeller: null,
    amazonBestSellerKeyword: '',
    listingFilterField: 'Module',
    listingThirdParty: null,
    queryModule: '',
    queryProductGroup: '',
    queryGoogleCategory: '',
    queryBrand: '',
    queryExactWebsite: '',
    queryAmazonBestSeller: '',
    queryCustomCollection: '',
    queryThirdParty: '',
    isSubmitting: false
};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        addInputList: (state, { payload }) => {
            const generateId = Math.random().toString(36).substring(8);
            state.inputList.push({
                id: generateId,
                ...payload
            });
        },
        updateInputList: (state, { payload }) => {
            const postIndex = state.inputList.findIndex((item: any) => item.id === payload.id);
            if (postIndex !== -1) {
                state.inputList[postIndex] = { ...state.inputList[postIndex], ...payload.data };
            }
        },
        resetInputListItem: (state, { payload }) => {
            const postIndex = state.inputList.findIndex((item: any) => item.id === payload.data.id);
            if (postIndex !== -1) {
                state.inputList[postIndex] = { ...payload.data };
            }
        },
        removeInputList: (state, { payload }) => {
            state.inputList = state.inputList.filter((item: any) => item.id !== payload.id);
        },
        changeInputListValue: (state, { payload }) => {
            state.inputList = payload;
        },
        resetInputList: (state) => {
            state.inputList = [{ id: '1', fieldSearch: 'Keywords', checkOption: 'Containing', includeExclude: 'Include', searchkey: [] }];

            state.checkFilter.bundles = true;
            state.checkFilter.refurbished = true;
            state.checkFilter.instock = true;
            state.checkFilter.hideMarketplace = true;
        },
        initializeInputList: (state, { payload }) => {
            state.inputList = payload;
        },
        updateBundle: (state, { payload }) => {
            state.checkFilter.bundles = payload;
        },
        updateStock: (state, { payload }) => {
            state.checkFilter.instock = payload;
        },
        updateMarketPlace: (state, { payload }) => {
            state.checkFilter.hideMarketplace = payload;
        },
        updateRefurbished: (state, { payload }) => {
            state.checkFilter.refurbished = payload;
        },
        updateDatePicker: (state, { payload }) => {
            state.datePicker = payload;
        },
        updateDateRangePicker: (state, { payload }) => {
            state.dateRangePicker = payload;
        },
        updateAmazonBestSeller: (state, { payload }) => {
            state.amazonBestSeller = payload;
        },
        updateAmazonBestSellerKeyword: (state, { payload }) => {
            state.amazonBestSellerKeyword = payload;
        },
        updateModuleQuery: (state, { payload }) => {
            state.queryModule = payload;
        },
        updateProductGroupQuery: (state, { payload }) => {
            state.queryProductGroup = payload;
        },
        updateGoogleCategoryQuery: (state, { payload }) => {
            state.queryGoogleCategory = payload;
        },
        updateBrandQuery: (state, { payload }) => {
            state.queryBrand = payload;
        },
        updateExactWebsiteQuery: (state, { payload }) => {
            state.queryExactWebsite = payload;
        },
        updateCustomCollectionQuery: (state, { payload }) => {
            state.queryCustomCollection = payload;
        },
        updateThirdPartyQuery: (state, { payload }) => {
            state.queryThirdParty = payload;
        },
        updateAmazonBestSellerQuery: (state, { payload }) => {
            state.queryAmazonBestSeller = payload;
        },
        updateAmazonFilterField: (state, { payload }) => {
            state.amazonFilterField = payload;
        },
        updateListingGoogleCategory: (state, { payload }) => {
            state.inputList = [
                {
                    id: '1',
                    checkOption: 'Is',
                    includeExclude: 'Include',
                    fieldSearch: 'Google Category',
                    google: payload
                }
            ];
        },
        updateListingModule: (state, { payload }) => {
            state.inputList = [
                {
                    checkOption: 'Is',
                    fieldSearch: 'Module',
                    id: '1',
                    mmsmodule: payload
                }
            ];
        },
        updateListingProductGroup: (state, { payload }) => {
            state.inputList = [
                {
                    checkOption: 'Is',
                    fieldSearch: 'Product Group',
                    id: '1',
                    mmscategory: payload
                }
            ];
        },

        updateListingThirdParty: (state, { payload }) => {
            state.listingThirdParty = payload;
            state.inputList = [
                {
                    checkOption: 'Is',
                    fieldSearch: 'Module',
                    id: '1',
                    mmsmodule: payload
                }
            ];
        },
        updateListingFilterField: (state, { payload }) => {
            state.listingFilterField = payload;
        },

        submitFilter: (state) => {
            state.isSubmitting = true;
        },
        unSubmitFilter: (state) => {
            state.isSubmitting = false;
        },
        resetFilter: (state, { payload }) => {
            const websitesRegions = userWebsites ? JSON.parse(userWebsites).filter((el: any) => el.region == payload)[0].websites : [];
            const defaultWebsiteData = websitesRegions.filter((elm: any) => elm.default)[0];

            return {
                inputList: [{ id: '1', fieldSearch: 'Keywords', checkOption: 'Containing', includeExclude: 'Include', searchkey: [] }],
                checkFilter: {
                    bundles: true,
                    refurbished: true,
                    instock: true,
                    hideMarketplace: true
                },
                datePicker: defaultWebsiteData
                    ? moment(new Date(defaultWebsiteData.data.lastDataDate), 'MM-DD-YYYY')
                    : moment(new Date(), 'MM-DD-YYYY'),
                dateRangePicker: {
                    startDate: moment(moment().subtract(3, 'months'), 'MM-DD-YYYY'),
                    endDate: moment(moment(), 'MM-DD-YYYY')
                },
                amazonFilterField: 'amazon-category',
                amazonBestSeller: null,
                amazonBestSellerKeyword: '',
                listingFilterField: 'Module',
                listingThirdParty: null,
                queryModule: '',
                queryProductGroup: '',
                queryGoogleCategory: '',
                queryBrand: '',
                queryExactWebsite: '',
                queryAmazonBestSeller: '',
                queryThirdParty: '',
                isSubmitting: false
            };
        },
        resetInitialStateFilter: () => initialState
    }
});

export const {
    addInputList,
    updateInputList,
    changeInputListValue,
    resetInputListItem,
    removeInputList,
    resetInputList,
    initializeInputList,
    updateBundle,
    updateStock,
    updateMarketPlace,
    updateRefurbished,
    updateDatePicker,
    updateDateRangePicker,
    updateAmazonBestSeller,
    updateAmazonBestSellerKeyword,
    updateModuleQuery,
    updateProductGroupQuery,
    updateGoogleCategoryQuery,
    updateBrandQuery,
    updateExactWebsiteQuery,
    updateAmazonBestSellerQuery,
    updateCustomCollectionQuery,
    updateAmazonFilterField,
    updateListingGoogleCategory,
    updateListingModule,
    updateListingProductGroup,
    updateListingThirdParty,
    updateListingFilterField,
    updateThirdPartyQuery,
    submitFilter,
    unSubmitFilter,
    resetFilter,
    resetInitialStateFilter
} = filterSlice.actions;

export default filterSlice.reducer;
