import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    accuracyFilterField: 'Module',
    moduleValue: null,
    productGroupValue: null,
    queryModule: '',
    queryProductGroup: '',
    // Google Listings Filter
    googleListingWizardValue: null,
    queryGoogleListingWizard: '',
    // Listing Wizard Filter
    listingWizardField: 'Module',
    listingModuleValue: null,
    queryListingModule: '',
    listingProductGroupValue: null,
    queryListingProductGroup: '',
    listingWizardThirdPartyValue: null,
    queryListingWizardThirdParty: ''
};

const customFilterSlice = createSlice({
    name: 'customFilter',
    initialState,
    reducers: {
        updateAccuracyFilterField: (state, { payload }) => {
            state.accuracyFilterField = payload;
            state.moduleValue = null;
            state.productGroupValue = null;
        },
        updateModuleValue: (state, { payload }) => {
            state.moduleValue = payload;
        },
        updateProductGroupValue: (state, { payload }) => {
            state.productGroupValue = payload;
        },
        updateQueryModule: (state, { payload }) => {
            state.queryModule = payload;
        },
        updateQueryProductGroup: (state, { payload }) => {
            state.queryProductGroup = payload;
        },

        // Google Listings Filter
        updateGoogleListingWizardValue: (state, { payload }) => {
            state.googleListingWizardValue = payload;
        },
        updateQueryGoogleListingWizard: (state, { payload }) => {
            state.queryGoogleListingWizard = payload;
        },
        // Listing Wizard Filter
        updateListingWizardField: (state, { payload }) => {
            state.listingWizardField = payload;
        },
        updateListingModuleValue: (state, { payload }) => {
            state.listingModuleValue = payload;
            state.listingProductGroupValue = null;
            state.listingWizardThirdPartyValue = null;
        },
        updateQueryListingModule: (state, { payload }) => {
            state.queryListingModule = payload;
        },
        updateListingProductGroupValue: (state, { payload }) => {
            state.listingProductGroupValue = payload;
            state.listingModuleValue = null;
            state.listingWizardThirdPartyValue = null;
        },
        updateQueryListingProductGroup: (state, { payload }) => {
            state.queryListingProductGroup = payload;
        },
        updateListingWizardThirdPartyValue: (state, { payload }) => {
            state.listingWizardThirdPartyValue = payload;
            state.listingModuleValue = null;
            state.listingProductGroupValue = null;
        },
        updateQueryListingWizardThirdParty: (state, { payload }) => {
            state.queryListingWizardThirdParty = payload;
        },
        resetAccuracyFilter: () => initialState
    }
});

export const {
    updateAccuracyFilterField,
    updateModuleValue,
    updateProductGroupValue,
    updateQueryModule,
    updateQueryProductGroup,
    resetAccuracyFilter,
    updateGoogleListingWizardValue,
    updateListingWizardField,
    updateListingModuleValue,
    updateQueryListingModule,
    updateListingProductGroupValue,
    updateQueryListingProductGroup,
    updateListingWizardThirdPartyValue,
    updateQueryListingWizardThirdParty,
    updateQueryGoogleListingWizard
} = customFilterSlice.actions;

export default customFilterSlice.reducer;
