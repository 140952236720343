import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import MainLayout from 'layout/MainLayout';
import AuthGuard from 'common/auth-guard';
import Loadable from 'components/common/UI/Loadable';

const ListingWizard = Loadable(lazy(() => import('views/dashboard/ListingWizard/ListingWizardPage')));
const GoogleListing = Loadable(lazy(() => import('views/dashboard/GoogleListing/GoogleListingWizardPage')));
const CustomGoogleListingWizardPage = Loadable(lazy(() => import('views/dashboard/CustomGoogleListing/CustomGoogleListingWizardPage')));
const FreshCatalog = Loadable(lazy(() => import('views/dashboard/FreshCatalogView/CatalogPage')));
const OpportunitiesPage = Loadable(lazy(() => import('views/dashboard/OpportunitiesView/OpportunitiesPage')));
const GfkOpportunitiesPage = Loadable(lazy(() => import('views/dashboard/GFKOpportunitiesView/GFKOpportunitiesPage')));
const ExclusiveProductsPage = Loadable(lazy(() => import('views/dashboard/ExclusiveProducts/ExclusiveProductsPage')));
const NewProducts = Loadable(lazy(() => import('views/dashboard/NewProducts/NewProductsPage')));
const NewlyDiscoveredPage = Loadable(lazy(() => import('views/dashboard/NewlyDiscovered/NewlyDiscoveredPage')));
const AmazonBestSellerPage = Loadable(lazy(() => import('views/dashboard/AmazonBestSeller/AmazonBestSellerPage')));
const BestRated = Loadable(lazy(() => import('views/dashboard/BestRated/BestRatedPage')));
const CampaignManager = Loadable(lazy(() => import('views/dashboard/CampaignManagerAnalytics/campaignManagerPage')));
const PriceHistory = Loadable(lazy(() => import('views/dashboard/PriceHistoryView/PriceHistoryPage')));
const CompetitorPricing = Loadable(lazy(() => import('views/dashboard/CompetitorPricing/competitorPricingPage')));
const DailyChanges = Loadable(lazy(() => import('views/dashboard/DailyChanges/DailyChangesPage')));
const MultivariateAnalysis = Loadable(lazy(() => import('views/dashboard/MultivariateAnalysisView/MultivariateAnalysisPage')));
const OverviewPage = Loadable(lazy(() => import('views/dashboard/AssortmentAnalytics/OverviewPage')));
const Relevance = Loadable(lazy(() => import('views/dashboard/RelevanceView/RelevancePage')));
const AllGFKProducts = Loadable(lazy(() => import('views/dashboard/AllGFKProducts/AllGFKProductsPage')));
const StateOfMatchingPage = Loadable(lazy(() => import('views/dashboard/StateOfMatchingView/StateOfMatchingPage')));
const Billing = Loadable(lazy(() => import('views/settings/Billing')));
const NotFoundPage = Loadable(lazy(() => import('views/settings/NotFoundPage')));

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Navigate replace to="/assortment/relevant" />
        },
        {
            path: '/assortment',
            element: <OverviewPage title="Assortement | Suite 360" />
        },
        {
            path: '/assortment/listing',
            element: <ListingWizard title="Listing Wizard | Suite 360" />
        },
        {
            path: '/assortment/googlelisting',
            element: <GoogleListing title="Google Listing Wizard | Suite 360" />
        },
        {
            path: '/assortment/googlelistingcustom',
            element: <CustomGoogleListingWizardPage title="Google Listing Wizard | Suite 360" />
        },
        {
            path: '/catalog',
            element: <FreshCatalog title="Last Crawl | Suite 360" />
        },
        {
            path: '/assortment/relevant',
            element: <Relevance title="Relevance Products | Suite 360" />
        },
        // {
        //     path: '/assortment/gfkproducts',
        //     element: <AllGFKProducts title="GFK Products | Suite 360" />
        // },
        {
            path: '/assortment/opportunities',
            element: <OpportunitiesPage title="Products Opportunities | Suite 360" />
        },
        // {
        //     path: '/assortment/gfkopportunities',
        //     element: <GfkOpportunitiesPage title="GFK Opportunities | Suite 360" />
        // },
        // {
        //     path: '/assortment/exclusive',
        //     element: <ExclusiveProductsPage title="Exclusives Products | Suite 360" />
        // },
        {
            path: '/assortment/newdescoveries',
            element: <NewProducts title="New Products | Suite 360" />
        },
        {
            path: '/assortment/newlydiscovered',
            element: <NewlyDiscoveredPage title="New In The Market | Suite 360" />
        },
        {
            path: '/amazonanalytics/amazonbestseller',
            element: <AmazonBestSellerPage title="Amazon Best Sellers | Suite 360" />
        },
        {
            path: '/assortment/bestrated',
            element: <BestRated title="Best Rated | Suite 360" />
        },
        // {
        //     path: '/campaign/manager',
        //     element: <CampaignManager title="Campaing Manager | Suite 360" />
        // },
        {
            path: '/pricing/changes',
            element: <DailyChanges title="Daily Changes | Suite 360" />
        },
        {
            path: '/pricing/competitors',
            element: <CompetitorPricing title="Competitors pricing | Suite 360" />
        },
        {
            path: '/pricing/urls',
            element: <PriceHistory title="Product Pricing | Suite 360" />
        },
        {
            path: '/pricing/multivariate',
            element: <MultivariateAnalysis title="Product Multivariates | Suite 360" />
        },
        {
            path: '/settings/stats',
            element: <StateOfMatchingPage title="State of Matching | Suite 360" />
        },
        {
            path: '/settings/billing',
            element: <Billing title="Billing | Suite 360" />
        },
        {
            path: '/login',
            element: <Navigate to="/assortment/relevant" />
        },
        {
            path: '/register',
            element: <Navigate to="/assortment/relevant" />
        },
        {
            path: '/forgot',
            element: <Navigate to="/assortment/relevant" />
        },
        {
            path: '/new-password',
            element: <Navigate to="/assortment/relevant" />
        }
    ]
};

export const NotFoundRoutes = {
    path: '*',
    element: <NotFoundPage title="Page not found | Suite 360" />
};

export default MainRoutes;
