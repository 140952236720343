import { AppBar, IconButton, Toolbar, Theme, useMediaQuery, Stack, Divider, Box } from '@mui/material';
import { FiMenu, FiMoon, FiSun, FiUser } from 'react-icons/fi';

import { useDispatch, useSelector } from 'react-redux';

import { usePopover } from 'hooks/use-propover';
import { changeTheme } from 'store/slices/auth/authSlice';
import Logo from 'components/common/UI/Logo';
import SettingsPropover from './SettingsPropover';
import CountryPopover from './CountryPopover';
import { changeMobileSideBar, changeSideBar } from 'store/slices/sidebar/sidebarSlice';

interface NewHeaderProps {
    sx: object;
    customClass?: string;
    toggleSidebar: any;
    toggleMobileSidebar: any;
}

export default function NewHeader({ sx, customClass }: NewHeaderProps) {
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const accountPopover = usePopover();
    const dispatch = useDispatch();

    const { isDark } = useSelector((state: any) => state.auth);

    const toggleTheme = () => {
        const storedTheme = isDark ? 'light' : 'dark';

        localStorage.setItem('theme', storedTheme);
        dispatch(changeTheme());
    };

    const handleOpenMobileDrawer = () => {
        dispatch(changeMobileSideBar());
    };
    const handleChangeDesktopDrawer = () => {
        dispatch(changeSideBar());
    };

    return (
        <AppBar sx={sx} elevation={0} className={customClass}>
            <Toolbar>
                {lgUp && (
                    <Box sx={{ width: '250px' }}>
                        <Logo width="150px" />
                    </Box>
                )}
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleChangeDesktopDrawer}
                    size="large"
                    sx={{
                        display: {
                            lg: 'flex',
                            xs: 'none'
                        }
                    }}
                >
                    <FiMenu size={24} />
                </IconButton>

                <IconButton
                    size="large"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleOpenMobileDrawer}
                    sx={{
                        display: {
                            lg: 'none',
                            xs: 'flex'
                        }
                    }}
                >
                    <FiMenu size={20} />
                </IconButton>

                {!lgUp && <Box sx={{ flexGrow: 1 }} />}
                {!lgUp && (
                    <Box sx={{ width: '150px', pt: '8px' }}>
                        <Logo width="150px" />
                    </Box>
                )}

                <Box flexGrow={1} />
                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                    <CountryPopover />
                    <Divider orientation="vertical" flexItem />
                    <IconButton aria-label="theme" size="large" onClick={toggleTheme} color="inherit">
                        {isDark ? <FiSun size={20} /> : <FiMoon size={20} />}
                    </IconButton>

                    <IconButton
                        aria-label="settings"
                        size="large"
                        onClick={accountPopover.handleOpen}
                        ref={accountPopover.anchorRef}
                        color="inherit"
                    >
                        <FiUser size={20} />
                    </IconButton>
                </Stack>
                <SettingsPropover
                    anchorEl={accountPopover.anchorRef.current}
                    open={accountPopover.open}
                    handleClose={accountPopover.handleClose}
                />
            </Toolbar>
        </AppBar>
    );
}
