type websiteType = {
    region: string;
    websites: [];
};

const sanitizeWbsites = (websites: websiteType[], selectedRegion: string) => {
    return websites.find((item: websiteType) => {
        if (item.region == selectedRegion) {
            return item;
        }
    });
};

function initChartData(chart: any, type = '') {
    chart.title.text = '';
    chart.series = [];
    chart.legend.data = [];

    if (type && type == 'bar') {
        // chart.color = [];
        // chart.xAxis = {
        //     type: 'category',
        //     data: []
        // };
        // chart.yAxis = {
        //     type: 'value',
        //     scale: true
        // };
    }
    return chart;
}

function configureChartOption(options: any, products: any, dataObj: string, axis: string) {
    options.title.text = '';
    if (!products[dataObj][axis].data || !products[dataObj][axis].data.length) {
        options.title.text = "Nothing to Show 🥺. There's no data to load, please check your filter.";
        return options;
    }
    if (products && products.error) {
        options.title.text = `${products.error}`;
        return;
    }
    options[axis].data = products[dataObj][axis].data;
    options.series = products[dataObj].series;

    const names: any = [];
    options.series.forEach((item: any) => {
        if (item.name) names.push(item.name as never);
    });
    options.legend.data = names;

    return options;
}

function getCountryCode(countryCode: any) {
    switch (countryCode.toLowerCase()) {
        case 'swe':
            return 'se';
        case 'se':
            return 'swe';
        case 'usa':
            return 'us';
        case 'uk':
            return 'gb';

        default:
            return countryCode;
    }
}

export type components = {
    name: string;
    allowed: boolean;
};

function handleMenuItem(allComponents: components[]) {
    const components: components[] = [];

    const overview = allComponents.find((item: components) => item.name == 'assortment') || { name: 'assortment', allowed: true };
    const freshCatalog = allComponents.find((item: components) => item.name == 'freshcatalog') || {
        name: 'freshcatalog',
        allowed: true
    };
    const relevance = allComponents.find((item: components) => item.name == 'relevance') || { name: 'relevance', allowed: true };
    const opportunities = allComponents.find((item: components) => item.name == 'opportunities') || {
        name: 'opportunities',
        allowed: true
    };
    const newProducts = allComponents.find((item: components) => item.name == 'newproducts') || { name: 'newproducts', allowed: true };
    const bestRated = allComponents.find((item: components) => item.name == 'bestrated') || { name: 'bestrated', allowed: true };
    const priceHistory = allComponents.find((item: components) => item.name == 'pricinghistory') || {
        name: 'pricinghistory',
        allowed: true
    };
    const multivariates = allComponents.find((item: components) => item.name == 'pricingmultivariates') || {
        name: 'pricingmultivariates',
        allowed: true
    };

    if (allComponents.length > 0) {
        allComponents.map((item: components) => {
            if (item.allowed == true) components.push(item);
        });
    }

    const menuData = [...components, overview, freshCatalog, relevance, opportunities, newProducts, bestRated, priceHistory, multivariates];

    return menuData.filter((item: components) => item.allowed).map((item: components) => item.name);
}

function hexToRGB(hex: string, alpha: number) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
}

function isValidUrl(urlString: string) {
    const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
}

export { sanitizeWbsites, configureChartOption, getCountryCode, initChartData, handleMenuItem, hexToRGB, isValidUrl };
