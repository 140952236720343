import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertColor } from '@mui/material';

export interface NotificationState {
    open?: boolean;
    type?: AlertColor;
    message?: string;
    timeout?: number | null;
    vertical?: 'bottom' | 'top';
    horizontal?: 'left' | 'right' | 'center';
}

export const notificationInitialState: NotificationState = {
    open: false,
    type: 'info',
    message: '',
    timeout: 5000,
    vertical: 'bottom',
    horizontal: 'center'
};

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: notificationInitialState,
    reducers: {
        addNotification: (_state, action: PayloadAction<NotificationState>) => ({
            ...notificationInitialState,
            ...action.payload,
            open: true
        }),
        clearNotification: (state) => {
            state.open = false;
        }
    }
});

export const NotificationActions = notificationSlice.actions;

export const { addNotification, clearNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
