import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, MenuItem, Stack, IconButton, Popover } from '@mui/material';
import ReactCountryFlag from 'react-country-flag';
import { updateRegion, updateSelectedWebsites } from 'store/slices/auth/authSlice';
import { getCountryCode } from 'components/utils/functions';
import Scrollbar from 'components/common/UI/ScrollBar';
import { initializeInputList, resetFilter } from 'store/slices/filter/filterSlice';
import getRegionDisplayName from 'components/utils/getRegionDisplayName';
import { resetAccuracyFilter } from 'store/slices/filter/customFilterSlice';

interface WEBSITE_TYPE {
    value: string;
    label: string;
    icon: string;
}
const WEBSITES: WEBSITE_TYPE[] = [];

export default function CountryPopover() {
    const { userRegions, userWebsites, selectedRegion } = useSelector((state: any) => state.auth);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(null);
    const [websites, setWebsites] = useState<WEBSITE_TYPE[]>(WEBSITES);
    const [reloadFlag, setReloadFlag] = useState(false);

    const handleOpen = (event: any) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleClick = (region: string) => {
        if (region && region !== selectedRegion) {
            dispatch(resetFilter(region));
            dispatch(resetAccuracyFilter());
            dispatch(updateRegion(region));

            const websites = userWebsites.filter((item: any) => item.region === region)[0].websites;

            const inputListData = [
                { id: '1', fieldSearch: 'Keywords', checkOption: 'Containing', includeExclude: 'Include', searchkey: [] }
            ];

            dispatch(initializeInputList(inputListData));
            dispatch(updateSelectedWebsites(websites));
            reloadTabs();
        }
        handleClose();
    };

    const reloadTabs = () => {
        const broadcastChannel = new BroadcastChannel('switch-region');
        broadcastChannel.postMessage({ action: 'reload' });
    };

    useEffect(() => {
        const broadcastChannel = new BroadcastChannel('switch-region');
        broadcastChannel.onmessage = (event) => {
            if (event.data.action === 'reload') {
                setReloadFlag(true);
            }
        };
    }, []);

    useEffect(() => {
        if (reloadFlag) {
            window.location.reload();
            window.location.href = '/assortment/relevant';
        }
    }, [reloadFlag]);

    useEffect(() => {
        const newWebsites = userRegions.map((lan: string) => {
            return {
                value: lan,
                label: getCountryCode(lan),
                icon: `/assets/icons/ic_flag_${lan}.svg`
            };
        });
        setWebsites(newWebsites);
    }, []);

    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44
                }}
            >
                <ReactCountryFlag countryCode={getCountryCode(selectedRegion)} svg />
            </IconButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75
                        }
                    }
                }}
            >
                <Scrollbar style={{ maxHeight: 300 }}>
                    <Stack spacing={0.75}>
                        {websites.map((option) => (
                            <MenuItem
                                key={option.value}
                                selected={option.value === selectedRegion}
                                onClick={() => handleClick(option.value)}
                            >
                                <Box sx={{ width: 28, mr: 2 }}>
                                    <ReactCountryFlag
                                        countryCode={option.label}
                                        svg
                                        style={{
                                            width: '22px',
                                            height: 'auto'
                                        }}
                                    />
                                </Box>

                                {getRegionDisplayName(option.label.toUpperCase())}
                            </MenuItem>
                        ))}
                    </Stack>
                </Scrollbar>
            </Popover>
        </>
    );
}
