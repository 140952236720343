import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { gqlUrl } from 'constants/graphql';

const httpLink = new HttpLink({
    uri: gqlUrl,
    fetchOptions: {
        mode: 'cors' // no-cors, *cors, same-origin
    }
});

const authLink = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            token: `65e6e81286fc99b950b0f124`
        },
        credentials: 'include'
    });
    return forward(operation);
});

setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            token: `65e6e81286fc99b950b0f124`
        },
        credentials: 'include'
    };
});

const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        const Unauthorized = graphQLErrors.filter((el: any) => el.message == 'Unauthorized');
        if (Unauthorized && Unauthorized.length) {
            console.log('Unauthorized :', Unauthorized);
            localStorage.clear();
        }
    }
});

const appoloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: {
        query: {
            fetchPolicy: 'no-cache'
        },
        mutate: {
            fetchPolicy: 'no-cache'
        }
    }
});

export default appoloClient;
