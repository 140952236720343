import { Skeleton } from '@mui/material';
import { Fragment } from 'react';

interface MenuItemLoadingProps {
    height?: number;
    radius?: number;
}

function MenuItemLoading({ height, radius = 9 }: MenuItemLoadingProps) {
    const skeletonsArray = Array.from(Array(5).keys());

    return (
        <Fragment>
            {skeletonsArray.map((item) => (
                <Skeleton variant="rectangular" height={height} width={'100%'} sx={{ mb: 2, borderRadius: `${radius}px` }} key={item} />
            ))}
        </Fragment>
    );
}

export default MenuItemLoading;
