export default function getRegionDisplayName(regionCode: string): string {
    // Define a mapping of region codes to display names
    const regionNames: { [key: string]: string } = {
        US: 'United States',
        USA: 'United States',
        CA: 'Canada',
        GB: 'United Kingdom',
        ES: 'Spain',
        NL: 'Netherlands',
        TR: 'Turkey',
        DE: 'Germany',
        PT: 'Portugal',
        IT: 'Italy',
        FR: 'France',
        SWE: 'Sweden',
        SE: 'Sweden',
        AT: 'Austria',
        PL: 'Poland',
        UK: 'United Kingdom',
        HU: 'Hungary',
        CH: 'Switzerland',
        BE: 'Belgium'
    };

    return regionNames[regionCode] || regionCode;
}
