import { Box, ListItemIcon, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { FiDatabase } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';
import MenuItemLoading from 'components/common/skeletons/MenuItemLoading';

interface SettingsPropoverProps {
    anchorEl: HTMLButtonElement | null;
    handleClose: () => void;
    open: boolean;
}

function SettingsPropover({ anchorEl, handleClose, open }: SettingsPropoverProps) {
    const navigate = useNavigate();
    const location = useLocation();

    const { loadingMenuItems } = useAppSelector((state) => state.auth);

    return (
        <Popover
            id="account-settings-popover"
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
        >
            <MenuList
                disablePadding
                dense
                sx={{
                    p: 1,
                    '& > *': {
                        borderRadius: 2
                    }
                }}
            >
                {loadingMenuItems ? (
                    <Box sx={{ width: 150 }}>
                        <MenuItemLoading height={20} radius={2} />
                    </Box>
                ) : (
                    <Box>
                        <MenuItem
                            sx={{
                                mb: 1,
                                borderRadius: 1,
                                ...(location.pathname === '/settings/stats' && {
                                    color: 'white',
                                    backgroundColor: (theme) => `${theme.palette.primary.main}!important`
                                })
                            }}
                            selected={location.pathname === '/settings/stats'}
                            onClick={() => {
                                handleClose();
                                navigate('/settings/stats');
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    ...(location.pathname === '/settings/stats' && {
                                        color: 'white'
                                    })
                                }}
                            >
                                <FiDatabase fontSize="small" />
                            </ListItemIcon>
                            <Typography>State Of Matching</Typography>
                        </MenuItem>
                    </Box>
                )}
            </MenuList>
        </Popover>
    );
}

export default SettingsPropover;
