import { Snackbar, Alert, SnackbarCloseReason, Box } from '@mui/material';
import Button from '@mui/material/Button';

import ReactHtmlParser from 'react-html-parser';

import AlertTitle from '@mui/material/AlertTitle';

import { useSelector } from 'react-redux';
import { RootState } from 'store/index';
import { useNotification } from 'hooks/useNotification';

export const Notification = (): JSX.Element => {
    const notification = useSelector((state: RootState) => state.notification);
    const { removeNotification } = useNotification();

    const handleClose = (_: unknown, reason?: SnackbarCloseReason) => reason !== 'clickaway' && removeNotification();

    const action = (
        <>
            <Button color="warning" size="medium" onClick={handleClose}>
                Notify
            </Button>
            <Button color="error" size="medium" onClick={handleClose} sx={{ backgroundColor: 'InfoBackground' }}>
                Dismiss
            </Button>
        </>
    );

    return (
        <Snackbar
            open={notification.open}
            autoHideDuration={notification.timeout}
            onClose={handleClose}
            anchorOrigin={{ vertical: notification.vertical || 'bottom', horizontal: notification.horizontal || 'center' }}
            action={action}
        >
            <Alert
                variant="filled"
                onClose={handleClose}
                severity={notification.type}
                sx={{ color: '#fff' }}
                action={notification.type == 'error' ? action : null}
            >
                <AlertTitle>{notification.type}</AlertTitle>
                <Box>{ReactHtmlParser(notification.message || '')}</Box>
            </Alert>
        </Snackbar>
    );
};
