import { FiBarChart2, FiCompass } from 'react-icons/fi';
import { MdOutlinePriceChange, MdOutlineFiberNew, MdOutlineProductionQuantityLimits } from 'react-icons/md';
import { AiOutlineAmazon, AiOutlineGoogle, AiOutlineStar, AiOutlineHistory, AiOutlineDollar, AiOutlineClockCircle } from 'react-icons/ai';
import { HiOutlineCalendarDays } from 'react-icons/hi2';
import { TbPerfume } from 'react-icons/tb';

export const sideBarItems = [
    {
        id: 'assortment',
        navlabel: true,
        subheader: 'Assortment Analytics',
        icon: <FiBarChart2 size={20} />
    },
    {
        id: 'assortment',
        title: 'Most Relevant',
        path: '/assortment/relevant',
        icon: <MdOutlineProductionQuantityLimits size={20} />
    },
    // {
    //     id: 'gfkproducts',
    //     title: 'All GFK Products',
    //     path: '/assortment/gfkproducts',
    //     icon: <TbPerfume size={20} />
    // },
    // {
    //     id: 'listing',
    //     title: 'Listing Wizard',
    //     path: '/assortment/listing',
    //     icon: <AiOutlineUnorderedList size={20} />
    // },
    // {
    //     id: 'googlelisting',
    //     title: 'Google Listing Wizard',
    //     path: '/assortment/googlelisting',
    //     icon: <AiOutlineGoogle size={20} />
    // },
    {
        id: 'googlelisting',
        title: 'Google Listing Wizard',
        path: '/assortment/googlelistingcustom',
        icon: <AiOutlineGoogle size={20} />
    },
    // {
    //     id: 'opportunities',
    //     title: 'All Opportunities',
    //     path: '/assortment/opportunities',
    //     icon: <BsCheckAll size={20} />
    // },
    // {
    //     id: 'gfkOpportunities',
    //     title: 'GFK Opportunities',
    //     path: '/assortment/gfkopportunities',
    //     icon: <BsFillTicketPerforatedFill size={20} />
    // },
    {
        id: 'newproducts',
        title: 'New In The Market',
        path: '/assortment/newlydiscovered',
        icon: <MdOutlineFiberNew size={20} />
    },
    {
        id: 'amazonanalytics',
        title: 'Amazon Best Seller',
        path: '/amazonanalytics/amazonbestseller',
        icon: <AiOutlineAmazon size={20} />
    },
    // {
    //     id: 'exclusive',
    //     title: 'My Exclusive Products',
    //     path: '/assortment/exclusive',
    //     icon: <TbLayout2 size={20} />
    // },
    {
        id: 'assortment',
        title: 'Last Crawl',
        path: '/catalog',
        icon: <AiOutlineClockCircle size={20} />
    },
    {
        id: 'assortment',
        title: 'Historical Crawl',
        path: '/assortment',
        icon: <FiCompass size={20} />
    },
    // {
    //     id: 'newproducts',
    //     title: 'Lately Listed Products',
    //     path: '/assortment/newdescoveries',
    //     icon: <MdOutlineFiberNew size={20} />
    // },
    {
        id: 'assortment',
        title: 'Best Rated',
        path: '/assortment/bestrated',
        icon: <AiOutlineStar size={20} />
    },

    // {
    //     id: 'campaignmanager',
    //     title: 'Campaign manager',
    //     path: '/campaign/manager',
    //     icon: <MdOutlineCampaign size={20} />
    // },

    {
        id: 'pricing',
        navlabel: true,
        subheader: 'Pricing Intelligence',
        icon: <MdOutlinePriceChange size={20} />
    },
    {
        id: 'pricingcompetitors',
        title: 'Competitor Pricing',
        path: '/pricing/competitors',
        icon: <AiOutlineDollar size={20} />
    },
    {
        id: 'pricingdaily',
        title: 'Daily Changes',
        path: '/pricing/changes',
        icon: <HiOutlineCalendarDays size={20} />
    },
    {
        id: 'pricing',
        title: 'Price History',
        path: '/pricing/urls',
        icon: <AiOutlineHistory size={20} />
    }
    // {
    //     id: 'pricing',
    //     title: 'Multivariate Analysis',
    //     path: '/pricing/multivariate',
    //     icon: <AiOutlineDotChart size={20} />
    // }
];
