import { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ACCESS_ACCOUNT_MUTATION } from 'services/authService/queries';
import appoloClient from './appolo-client';
import { useAppDispatch } from 'store/hooks';
import {
    addUserConfig,
    updateIsMMS,
    updateUserInfo,
    updateUserRegions,
    updateUserWebsites,
    updatedefaultWebsite,
    userLoggingOut,
    updateSelectedWebsites,
    updateRegion
} from 'store/slices/auth/authSlice';
import { resetInitialStateFilter, updateDatePicker, updateDateRangePicker } from 'store/slices/filter/filterSlice';
import { Box, Stack } from '@mui/system';
import Logo from 'components/common/UI/Logo';
import AppLoader from 'components/common/UI/AppLoader/AppLoader';
import moment from 'moment';
import CountryModal from 'components/auth/CountryModal';

const userRegions: any = ['es', 'de', 'nl', 'swe', 'it', 'at', 'pl', 'tr', 'ch', 'be'];

function AuthGuard({ children }: any) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleSelectRegion = (region: string) => {
        dispatch(updateRegion(region));
    };

    const [loading, setLoading] = useState(true);

    const { selectedRegion, userInfo } = useSelector((state: any) => state.auth);

    const fetchUser = useCallback(async (region: string) => {
        try {
            const response = await appoloClient.query({
                query: ACCESS_ACCOUNT_MUTATION,
                variables: { user: userInfo && userInfo.id ? userInfo.id : '65e6e81286fc99b950b0f124' }
            });

            if (response.data && response.data.accesses) {
                const accesses = response.data.accesses.list;
                dispatch(addUserConfig(accesses));

                const websites: any = accesses.map((item: any) => {
                    const defaultWebsite = item.account.website;
                    return {
                        region: item.account.config.region,
                        websites: item.account.websites.map((website: any) => ({
                            alias: website.alias,
                            name: website.name,
                            id: website.id,
                            data: website.data,
                            default: website.id == defaultWebsite.id
                        }))
                    };
                });
                const regions: string[] = accesses?.map((item: any) => item?.account?.config?.region);
                const userWebsites = websites.filter((item: any) => item.region === region)[0];
                const userDefaultWebsite = userWebsites?.websites?.filter((website: any) => website.default)[0];
                const userData = accesses.find((item: any) => item.account.config.region === region).user;

                if (userData.acceptPrivacy === false) {
                    dispatch(userLoggingOut());
                    dispatch(resetInitialStateFilter());
                    navigate('/', { replace: true });
                }

                dispatch(updateIsMMS(false));

                if (userData) dispatch(updateUserInfo(userData));

                if (websites.length) {
                    const storedSelectedWebsites: any = localStorage.getItem('selectedWebsites');

                    dispatch(updateUserWebsites(websites));

                    if (JSON.parse(storedSelectedWebsites)?.length) {
                        const websitesData = JSON.parse(storedSelectedWebsites);
                        const existingWebsitesIds = websitesData.map((item: any) => item.id);
                        const filteredWebsites = userWebsites.websites.filter((item: any) => existingWebsitesIds.includes(item.id));

                        dispatch(updateSelectedWebsites(filteredWebsites));
                    } else {
                        dispatch(updateSelectedWebsites(userWebsites.websites));
                    }

                    dispatch(updateUserRegions(regions));
                }

                const months = ['es', 'pt'].includes(region.toLowerCase()) ? { count: 1, type: 'months' } : { count: 8, type: 'weeks' };
                dispatch(
                    updateDateRangePicker({
                        startDate: moment(moment().subtract(months.count as any, months.type as any), 'MM-DD-YYYY'),
                        endDate: moment(moment(), 'MM-DD-YYYY')
                    })
                );
                const dateValue = userDefaultWebsite
                    ? moment(new Date(userDefaultWebsite.data.lastDataDate), 'MM-DD-YYYY')
                    : moment(new Date(), 'MM-DD-YYYY');
                dispatch(updateDatePicker(moment(dateValue, 'MM-DD-YYYY')));

                if (userDefaultWebsite) {
                    dispatch(updatedefaultWebsite(userDefaultWebsite));
                }
            }
        } catch (error) {
            // navigate('/', { replace: true });
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (selectedRegion) {
            fetchUser(selectedRegion);
        } else {
            handleOpen();
        }
    }, [selectedRegion]);

    if (!selectedRegion) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Stack spacing={5} direction="column">
                    <CountryModal open={open} items={userRegions} handleClose={handleClose} handleClick={handleSelectRegion} />
                </Stack>
            </Box>
        );
    }

    if (loading)
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Stack spacing={5} direction="column">
                    <Logo width={'250px'} />
                    <AppLoader />
                </Stack>
            </Box>
        );

    return children;
}

export default AuthGuard;
