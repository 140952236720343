import { Box, Typography } from '@mui/material';

function Footer() {
    const todayDate = new Date().getFullYear();
    return (
        <Box sx={{ p: 3, textAlign: 'center' }}>
            <Typography
                color={'text.secondary'}
                variant="body2"
            >{`© ${todayDate} All rights reserved by CRAWLO Data Intelligence Ltd.`}</Typography>
        </Box>
    );
}

export default Footer;
