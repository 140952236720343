import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpenSideBar: true,
    isOpenMobileSideBar: false
};

const themeSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        closeSideBar: (state) => {
            state.isOpenSideBar = false;
        },
        openSideBar: (state) => {
            state.isOpenSideBar = true;
        },
        changeSideBar: (state) => {
            state.isOpenSideBar = !state.isOpenSideBar;
        },
        changeMobileSideBar: (state) => {
            state.isOpenMobileSideBar = !state.isOpenMobileSideBar;
        },
        closeMobileSideBar: (state) => {
            state.isOpenMobileSideBar = false;
        }
    }
});

export const { closeSideBar, openSideBar, changeSideBar, changeMobileSideBar, closeMobileSideBar } = themeSlice.actions;

export default themeSlice.reducer;
