import { useEffect, useState } from 'react';
import { CssBaseline, StyledEngineProvider, ThemeProvider, Box, Typography } from '@mui/material';
import createCache, { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { useSelector } from 'react-redux';

import 'react-perfect-scrollbar/dist/css/styles.css';

import Routes from 'routes';
import { BuildTheme } from 'theme/theme-variable';
import { Notification } from 'components/utils/Notification';

import WifiOff from '@mui/icons-material/WifiOff';

const cache: EmotionCache = createCache({
    key: 'css',
    prepend: true
});

function App() {
    const { isDark } = useSelector((state: any) => state.auth);
    const [waitingWorker] = useState<any>({});

    // Online state
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        // Update network status
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
        };

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);

    useEffect(() => {
        const onUpdate = () => {
            // Perform actions when service worker updates
            console.log('Service worker updated!');
            waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
            window.location.reload();
        };

        // Add event listener for service worker updates
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('controllerchange', onUpdate);
        }

        // Clean up event listener when component unmounts
        return () => {
            if (navigator.serviceWorker) {
                navigator.serviceWorker.removeEventListener('controllerchange', onUpdate);
            }
        };
    }, []);

    const theme = BuildTheme({
        activeMode: isDark ? 'dark' : 'light'
    });

    if (!isOnline) {
        return (
            <Box sx={{ paddingTop: '23px', padding: '132px', textAlign: 'center' }}>
                <WifiOff />
                <Typography sx={{ fontSize: '34px' }}>Your Connection was Offline.</Typography>
                <Typography>Please check your Wifi Connection.</Typography>
            </Box>
        );
    }

    return (
        <CacheProvider value={cache}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Routes />
                    <Notification />
                </ThemeProvider>
            </StyledEngineProvider>
        </CacheProvider>
    );
}

export default App;
